/**
 * Composable to replace images of Magento Products with alternative images uploaded to the Contentful
 * How to use:
 * - Import composable in component where you have Magento products and Contentful Visual[] from "Alternative Visuals" field
 * - Use applyAltVisuals method on magento products to alter the images
 * - Use it like this - applyAltVisuals(products: MagentoProduct[], altVisuals: Visual[]) />
 **/
import { Visual } from 'diptyqueTheme/types/contentful/contentTypes';

import { CategoryInterface } from '~/modules/catalog/category/types';
import { Product } from '~/modules/catalog/product/types';

type ProductWithHover = Product & {
  hover_on_card?: string | undefined;
};

export function useAlternativeVisual() {
  /**
   * Apply altVisuals from Contentful (if they are set) to Magento products
   **/
  const applyAltVisuals = (products: ProductWithHover[], altVisuals: Visual[]): Product[] => {
    if (!altVisuals) return products;

    return products.map((product) => {
      const altVisual = getAltVisualBySKU(product.sku, altVisuals);
      const altHoverVisual = getAltVisualBySKU(product.sku, altVisuals, true);

      if (altVisual) {
        const url = altVisual.vis?.url || altVisual.visD?.url;
        if (!product.thumbnail) product.thumbnail = {};
        if (url) product.thumbnail.url = url;
      }

      if (altHoverVisual) {
        const hoverUrl = altHoverVisual.vis?.url || altHoverVisual.visD?.url;
        if (hoverUrl) product.hover_on_card = hoverUrl;
      }

      return product;
    });
  };

  /**
   * Apply altVisuals from Contentful (if they are set) to Magento categories
   **/
  const applyAltCategoryVisuals = (categories: CategoryInterface[], altVisuals: Visual[]): CategoryInterface[] => {
    if (!altVisuals) return categories;

    return categories.map((category: CategoryInterface) => {
      const altVisual = getAltVisualByCategoryID(String(category.id), altVisuals);
      const altHoverVisual = getAltVisualByCategoryID(String(category.id), altVisuals, true);

      if (altVisual) {
        const url = altVisual.vis?.url || altVisual.visD?.url;
        if (url) category.image = url;
      }

      if (altHoverVisual) {
        const hoverUrl = altHoverVisual.vis?.url || altHoverVisual.visD?.url;
        if (hoverUrl) category.image_hover = hoverUrl;
      }

      return category;
    });
  };

  /**
   * Get altVisuals by SKU from the list of Contentful altVisuals
   * respecting the "hover" role:
   * - if hover is true, it will return the Contentful Visual entry with the "hover" role
   * - if hover is false, it will return the Contentful Visual entry without the "hover" role
   **/
  const getAltVisualBySKU = (sku: string, altVisuals: Visual[], hover = false): Visual | undefined => {
    return altVisuals.find((altVisual) => {
      const hasSKU = altVisual.sku?.includes(sku);
      const hasHoverRole = altVisual.roles?.includes('hover');
      return hover ? hasSKU && hasHoverRole : hasSKU && !hasHoverRole;
    });
  };

  /**
   * Get altVisuals by Category ID from the list of Contentful altVisuals
   * respecting the "hover" role:
   * - if hover is true, it will return the Contentful Visual entry with the "hover" role
   * - if hover is false, it will return the Contentful Visual entry without the "hover" role
   **/
  const getAltVisualByCategoryID = (cid: string, altVisuals: Visual[], hover = false): Visual | undefined => {
    return altVisuals.find((altVisual) => {
      const hasSKU = altVisual.categories?.includes(cid);
      const hasHoverRole = altVisual.roles?.includes('hover');
      return hover ? hasSKU && hasHoverRole : hasSKU && !hasHoverRole;
    });
  };

  return {
    getAltVisualBySKU,
    applyAltVisuals,
    applyAltCategoryVisuals
  };
}
