import { defineStore } from 'pinia';
import type { Cart } from '~/modules/GraphQL/types';

interface CustomerState {
  cart: Cart;
  is_data_loading: boolean;
  is_cart_loading: boolean;
  is_booxi_loading: boolean;
  initial_loading: boolean;
  is_item_adding: boolean;
}

export const useCartStore = defineStore('cart', {
  state: (): CustomerState => ({
    is_data_loading: false,
    is_cart_loading: false,
    is_booxi_loading: false,
    initial_loading: true,
    is_item_adding: false,
    cart: {
      id: '',
      is_virtual: false,
      total_quantity: 0,
      shipping_addresses: []
    }
  })
});
