import {computed, ssrRef, useContext, useRoute} from '@nuxtjs/composition-api';

import { Logger } from '~/helpers/logger';
import { useContentful } from '~/integrations/contentful/src/composables/useContentful';
import { useContentfulGQL } from '~/integrations/contentful/src/composables/useContentfulGQL';

export function useContentfulPage(id = null) {
  id = id || 'home';

  const { query, queryResponse } = useContentfulGQL('getPage-' + id);
  const { getEntries, entries } = useContentful('getPage-' + id);
  const {
    app: { i18n }
  } = useContext();
  const route = useRoute();
  const page = ssrRef(null, 'useContentfulPage-' + id);
  const sectionList = ssrRef({}, 'useContentfulPage-' + id);
  const loading = ssrRef<boolean>(false, `useContentfulPage-loading-${id}`);
  const error = ssrRef(null, `useContentfulPage-error-${id}`);
  const locales = i18n.localeProperties.ctfFallbackLocales;

  const getPage = async (index = 0) => {
    try {
      loading.value = true;

      if (!locales[index]) {
        page.value = null;
        return;
      }

      await getEntries({
        content_type: 'page',
        'fields.slug': id,
        'fields.storeview': i18n.localeProperties.storeview,
        locale: locales[index],
        select: 'fields,sys.contentType',
        limit: 1,
        include: 6,
        preview: !!route.value.query.preview
      });


      if (entries.value?.items?.length === 0) {
        await getPage(index + 1);
      }

      page.value = entries.value?.items?.[0] ? toGQLformat(entries.value?.items?.[0]) : null;

      error.value = null;
    } catch (err) {
      error.value = err;
      Logger.error(`useContentful/${id}/query`, error);
    } finally {
      loading.value = false;
    }
  };

  const getPageBySlug = async (slug: string, index = 0) => {
    try {
      loading.value = true;

      if (!locales[index]) {
        page.value = null;
        return;
      }

      await query('getPage', {
        storeview: i18n.localeProperties.storeview,
        locale: locales[index],
        slug: slug
      });

      if (queryResponse.value?.pageCollection?.items?.length === 0) {
        await getPageBySlug(slug, index + 1);
      }

      page.value = queryResponse.value?.pageCollection?.items?.[0] ?? null;

      error.value = null;
    } catch (err) {
      error.value = err;
      Logger.error(`useContentfulGQL/${id}/query`, error);
    } finally {
      loading.value = false;
    }
  };

  const getSectionList = async (type: string, entityId: string, index = 0) => {
    try {
      loading.value = true;

      if (!locales[index]) {
        page.value = null;
        return;
      }

      let fieldsToMerge;
      const queryParams = {
        'fields.storeview': i18n.localeProperties.storeview,
        locale: locales[index],
        select: 'fields,sys.contentType',
        limit: 5,
        include: 6,
        preview: !!route.value.query.preview
      };

      if (type === 'pdp') {
        queryParams['content_type'] = 'productsWrapper';
        queryParams['fields.sku[in]'] = entityId;
        fieldsToMerge = ['belowBlocks', 'productBlocks'];
      } else if (type === 'plp') {
        queryParams['content_type'] = 'categoriesWrapper';
        queryParams['fields.categories[in]'] = entityId;
        fieldsToMerge = ['listingBlocks', 'belowBlocks'];
      } else if (type === 'collection') {
        queryParams['content_type'] = 'collectionWrapper';
        queryParams['fields.categories[in]'] = entityId;
        fieldsToMerge = ['blocks'];
      } else if (type === 'main') {
        queryParams['content_type'] = 'mainCategoryWrapper';
        queryParams['fields.categories[in]'] = entityId;
        fieldsToMerge = ['blocks'];
      }

      await getEntries(queryParams);

      if (entries.value?.items?.length === 0) {
        await getSectionList(type, entityId, index + 1);
      }

      sectionList.value = entries.value?.items?.length ? mergeEntriesLists(entries.value?.items, fieldsToMerge) : {};
      // console.log('sectionList OUTPUT', sectionList.value);

      error.value = null;
    } catch (err) {
      error.value = err;
      Logger.error(`useContentful/${id}/query`, error);
    } finally {
      loading.value = false;
    }
  };

  // Merge sectionLists from an array of entries into obe object where all lists are merged
  // Usage example: mergeEntriesLists(entriesArray, ['productBlocks', 'belowBlocks']);
  const mergeEntriesLists = (lists, fields) => {
    // Initialize a result object with keys from the fields array
    const result = fields.reduce((acc, field) => {
      acc[field] = [];
      return acc;
    }, {});

    if (!lists?.length) return result;

    lists.forEach((list) => {
      fields.forEach((field) => {
        if (list?.fields[field]) {
          // Concatenate the arrays from the specified field
          result[field] = result[field].concat(list.fields[field]?.map((b) => toGQLformat(b)) || []);
        }
      });
    });

    return result;
  };


  // Convert object structure to GraphQL response format
  // To provide compatibility with previous GraphQL requests approach
  // as current sections components are built relying on GraphQL response
  const toGQLformat = (obj: any) => {
    let res = obj;

    const capitalizeFirstLetter = (str) => {
      if (!str || typeof str !== 'string') return '';
      return str.charAt(0).toUpperCase() + str.slice(1);
    }

    const processEntry = (e) => {
      // console.log('=== PROCESSING ENTRY', e);
      const _entry = e?.fields ? e.fields : e;
      if (!_entry) return null;

      for (const key in _entry) {
        // console.log('PROCESSING ENTRY KEY', key, _entry[key]);
        if (key === 'sys') {
          // Do nothing
        } else if (typeof _entry[key] === 'object' && !Array.isArray(_entry[key])) {
          // console.log('PROCESSING ENTRY OBJECT', key, _entry[key]);
          _entry[key] = toGQLformat(_entry[key]);
        } else if (Array.isArray(_entry[key]) && _entry[key][0]?.sys?.type === 'Entry') {
          // console.log('PROCESSING ENTRY ARRAY', key, _entry[key]);
          _entry[key + 'Collection'] = { items: _entry[key].map((item) => toGQLformat(item)).filter(i => { return i !== null }) };
          delete _entry[key];
        }
      }

      const unknownTypename = 'Unknown (hint: add contentType data into ~/integrations/contentful/src/config/contentTypes.ts)'
      _entry.__typename = capitalizeFirstLetter(e?.sys?.contentType?.sys?.id) || unknownTypename;
      if (e?.sys?.id) _entry.sys = { id: e.sys.id };

      // console.log('PROCESSING ENTRY RESULT', _entry);
      return _entry;
    };

    const processAsset = (a) => {
      const file = a?.fields?.file || {};
      if (file.contentType) a.contentType = file.contentType;
      if (file?.url) a.url = file.url?.[0] === '/' ? 'https:' + file.url : file.url;
      if (file.details?.image?.height) a.height = file.details.image.height;
      if (file.details?.image?.width) a.width = file.details.image.width;

      return a;
    };

    if (typeof obj === 'object' && !Array.isArray(obj)) {
      if (obj.sys?.type === 'Entry') {
        res = processEntry(obj);
      } else if (obj.sys?.type === 'Asset') {
        res = processAsset(obj);
      }
    }

    return res;
  };

  return {
    error: computed(() => error.value),
    loading: computed(() => loading.value),
    page: computed(() => page.value),
    sectionList: computed(() => sectionList.value),
    getPage,
    getPageBySlug,
    getSectionList
  };
}
