import { defineStore } from 'pinia';

interface ProductState {
  isEngravingInfoOpen: boolean;
  isEngravingInfoProcessed: boolean;
}

export const useProductStore = defineStore('product', {
  state: (): ProductState => ({
    isEngravingInfoOpen: false,
    isEngravingInfoProcessed: false
  })
});
